<template>
  <div class="container">
    <b-row class="justify-content-center">
      <b-col md="6" sm="8" class="p-0 m-0">
        <b-card no-body class="p-0 m-0">
          <b-card-body class="p-4">
            <b-form>
              <h1 class="mb-4">OTP Verification</h1>

              <div v-if="!isActivating">
                <b-button v-if="$store.state.user.useOTP == 1" @click="deleteOTP" variant="danger" block
                  >Deactivate</b-button
                >
                <b-button v-else @click="createOTP" block style="background: #2176fc; color: white">Activate</b-button>
              </div>
              <div v-else>
                <img :src="qrImgData" style="display: block; margin: 0 auto 20px" />
                <b-form-group>
                  <label class="col-form-label">Secret Key</label>
                  <b-form-input type="text" class="form-control" placeholder="Secret Token" v-model="otpSecret" block />
                </b-form-group>
                <b-form-group class="mt-2">
                  <label class="col-form-label">OTP Number</label>
                  <input type="text" class="form-control" placeholder="Enter OTP Number" v-model="otpNumber" />
                </b-form-group>
                <b-button @click="verifyOTP" block style="background: #2176fc; color: white">Confirm</b-button>
              </div>
            </b-form>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import AuthService from '@/services/AuthService'
import UserService from '@/services/UserService'
import { mapGetters } from 'vuex'
import QRCode from 'qrcode'

export default {
  name: 'OtpVerification',
  created() {},
  mounted() {
    document.getElementsByClassName('app-body')[0].classList.remove('bg-white')
    this.getUserInfo()
  },
  computed: {
    ...mapGetters({
      user: 'getUser',
    }),
  },
  data() {
    return {
      isActivating: 0,
      qrImgData: null,
      otpSecret: '',
      otpNumber: '',
    }
  },
  watch: {
    user() {},
  },
  methods: {
    async createOTP() {
      const response = await AuthService.createOTP()
      if (response.status !== 200) {
        return
      }
      const { otpSecret, otpAuthUrl } = response.data
      this.otpSecret = otpSecret

      QRCode.toDataURL(otpAuthUrl, async (err, imageData) => {
        this.qrImgData = imageData
      })
      this.isActivating = true
      this.getUserInfo()
    },
    async verifyOTP() {
      const response = await AuthService.verifyOTP(this.user.email, this.otpNumber)
      if (response.status != 200) {
        return
      }
      await UserService.updateUser(this.user.id, {
        useOTP: 1,
      })
      if (response.status != 200) {
        return
      }
      this.isActivating = false
      this.getUserInfo()
    },
    async deleteOTP() {
      if (!confirm('Are you sure deactivate?')) {
        return
      }
      await UserService.updateUser(this.user.id, {
        useOTP: 0,
      })
      this.getUserInfo()
    },
  },
}
</script>
